<template>
  <a-modal title="设置目标交付物" v-model="visible" width="680px" wrapClassName="relationWorkModal">
    <div class="relation-work">
      <div class="work-list">
        <a-table :data-source="list" :pagination="false" rowKey="seqid">
<!--          <a-table-column title="ID" width="70px">-->
<!--            <template slot-scope="row">-->
<!--              <span>{{ row.seqid }}</span>-->
<!--            </template>-->
<!--          </a-table-column>-->
          <a-table-column title="类型" data-index="delivery_type_name" width="130px">
            <template #default="delivery_type_name, row, $index">
              <template>
                <a-select v-model="row.delivery_type" style="width: 100%" @change="deliveryTypeChange(row.delivery_type, $index)">
                  <a-select-option v-for="item in cat.delivery_type" :key="item.oid" :value="item.cat_code">
                    {{item.cat_name}}
                  </a-select-option>
                </a-select>
              </template>
            </template>
          </a-table-column>
          <a-table-column title="交付物名称" data-index="delivery_name">
          <template #default="delivery_name, row">
            <auto-input
                v-if="row.isPro"
                url="/prj/product/retrieve/byprj"
                style="width: 100%"
                value-key="prooid"
                label-key="product_name"
                v-model="row.obj_oid"
                :label.sync="row.delivery_name"
                :query="{prjoid: prjOid}"
            />
            <a-input v-else v-model="row.delivery_name"></a-input>
          </template>
        </a-table-column>
          <a-table-column title="数量" data-index="delivery_count" width="100px">
            <template #default="delivery_count, row">
              <template>
                <a-input v-model="row.delivery_count"></a-input>
              </template>
            </template>
          </a-table-column>
          <a-table-column title="操作" key="action" width="70px">
            <template #default="_, row, key">
              <a-space class="operation">
                <div @click="deleteDeliver(row.oid, key)">删除</div>
              </a-space>
            </template>
          </a-table-column>
        </a-table>
        <div class="adddeliver blue" @click="addDeliver">
          <a-icon type="plus" />
          新增目标交付物
        </div>
      </div>
    </div>
    <template slot="footer">
      <div>
        <a-button key="back" @click="visible = false">
          取消
        </a-button>
        <a-button key="submit" :loading="loading" type="primary"  @click="handleOkDebounce">
          确定
        </a-button>
      </div>
    </template>
  </a-modal>
</template>
<script>
import autoInput from "@/components/autoInput.vue";
import {fetch} from "@/utils/request";
import {debounce} from "lodash";

export default {
  name: "setDeliverModal",
  props: {
    prjOid: { // 项目oid
      type: String
    },
    issueOid: { // 任务oid
      type: String
    },
    deliveryList: { // 交付物列表
      type: Array
    }
  },
  components: {autoInput},
  data() {
    return {
      visible: false,
      cat: {
        delivery_type: []
      },
      list: [],
      isPro: false,
      loading: false,
    }
  },
  watch: {
    deliveryList: {
      deep: true,
      immediate: true,
      handler (val) {
        console.log('设置目标交付物:',val);
        if (val.length > 0) {
          this.list = JSON.parse(JSON.stringify(val))
        }
      }
    }
  },
  methods: {
    addDeliver() {
      console.log(this.list);
      this.list.push({
        seqid: "",
        delivery_name: "",
        delivery_type: "",
        delivery_type_name: "",
        delivery_count: "",
        obj_oid: "",
        prjoid: this.prjOid,
        issue_oid: this.issueOid
      })
    },
    deleteDeliver(oid, key) {
      this.list.splice(key, 1)
      if (oid) {
        fetch('post', '/prj/delivery/delete', {
          oid
        }).then((res) => {
          if (res.status === 0) {
            this.$emit('reload')
            this.$message.success('删除成功！')
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    open() {
      this.visible = true
      fetch("post", "/prj/cat/retrieve", {
        cat_type: 'delivery_type',
        prjoid: this.prjOid,
        page_size: 999
      }).then(res => {
        if (res.status === 0) {
          this.cat.delivery_type = res.data.datas
        }
      })
    },
    handleOkDebounce: debounce(function () {
      this.handleOk()
    }, 1000, {
      leading: true,
      trailing: false
    }),
    handleOk() {
      if (this.loading) return;
      this.loading = true
      fetch('post','/prj/delivery/patch/create', {
        items: this.list
      }).then((res) => {
        if (res.status === 0) {
          this.$message.success('设置成功！')
          this.$emit('reload')
          this.visible = false
        }else {
          this.$message.error(res.message)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    deliveryTypeChange(value, index) {
      let find = this.cat.delivery_type.find(e => e.cat_code === value)
      if (find) {
        console.log(find);
        this.list[index].delivery_type_name = find.cat_name
        console.log(value, index);
        if (find.temp10 === 'P') {
          this.list[index].isPro = true
        } else if (find.temp10 === 'D') {
          this.list[index].isPro = false
          this.list[index].delivery_count = 1
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.relationWorkModal {
  .ant-modal-body {
    padding: 0;
  }
}
/deep/ .ant-modal-title{
  font-weight: 500;
  font-size: 18px;
  color: #303030;
}
/deep/ .ant-table-header-column .ant-table-column-title{
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
/deep/ .ant-table-tbody > tr > td{
  color: #000000;
}
.icon{
  width: 21px;
  height: 21px;
  border-radius: 50%;
  margin-right: 8px;
}
.relation-work {
  padding: 0 17px;

  .blue {
    color: #4397E7;

  }

  .adddeliver {
    margin-top: 19px;
    margin-bottom: 14px;
    cursor: pointer;
  }


}

.operation{
  color: #4397E7;
  >div{
    cursor: pointer;
  }
}

.footer_left {
  float: left;
  font-weight: 500;
  font-size: 14px;
  color: #616161;
}
</style>