<template>
  <div class="app-main-content">
    <div class="header">
      <div class="header-top">
        <div class="header-title">
          <div class="name">里程碑</div>
          <div class="id">#{{ issueData.seqid }}</div>
        </div>
        <div class="header-btns">
          <a-space>
            <a-button @click="back">
              <img :src="`${$appsettings.imgPrefix}back.png`" alt="">
              返回
            </a-button>
          </a-space>
        </div>
      </div>
      <div class="header-bottom">
        <div class="header-title">
          <edp-input
            style="--fontSize: 18px;width: 100%;"
            size="big"
            v-model="issueData.issue_name"
            :db="dbs.prjIssue"
            :prj-oid="$route.query.prj_oid"
            :oid="issueData.oid"
            field-name="issue_name"
          ></edp-input>
        </div>
        <div class="header-info">
          <div class="item">
            <a-avatar :size="32"
                      :src="$toUrl(issueData?.owners[0]?.pic)"/>
            <div class="user">
              <div class="name">
                <edp-select
                  size="mini"
                  v-model="issueData.issue_owner"
                  style="width: 120px;"
                  account="prj"
                  @change="getIssueDetails(oid)"
                  :db="dbs.prjIssue"
                  :prj-oid="$route.query.prj_oid"
                  :oid="issueData.oid"
                  field-name="issue_owner"
                ></edp-select>
              </div>
              <div class="job">负责人</div>
            </div>
          </div>
          <div class="item">
            <a-avatar :size="32"
                      :src="`${$appsettings.imgPrefix}shijian.png`"/>
            <div class="user">
              <div class="name">
<!--                {{issueData.deadline}}-->
                <edp-date-picker
                  style="width: 150px"
                  v-model="issueData.schedule_end_date"
                  :db="dbs.prjIssue"
                  field-name="schedule_end_date"
                  :oid="issueData.oid"
                  :prj-oid="$route.query.prj_oid"
                  size="mini"
                ></edp-date-picker>
              </div>
              <div class="job">计划结束</div>
            </div>
          </div>
          <div class="item">
            <a-avatar :size="32"
                      :src="`${$appsettings.imgPrefix}jingdu.png`"/>
            <div class="user">
              <div class="name">
                <edp-select
                  size="mini"
                  v-model="issueData.issue_status"
                  :db="dbs.prjIssue"
                  field-name="issue_status"
                  :oid="issueData.oid"
                  :prj-oid="$route.query.prj_oid"
                  cat-type="issue_bstatus"
                >
                </edp-select>
              </div>
              <div class="job">状态</div>
            </div>
          </div>
          <div class="item">
            <a-avatar :size="32"
                      :src="`${$appsettings.imgPrefix}shijian.png`"/>
            <div class="user">
              <div class="name">
                <!--                {{issueData.deadline}}-->
                <edp-date-picker
                  style="width: 150px"
                  v-model="issueData.actual_end_date"
                  :db="dbs.prjIssue"
                  field-name="actual_end_date"
                  :oid="issueData.oid"
                  :prj-oid="$route.query.prj_oid"
                  size="mini"
                ></edp-date-picker>
              </div>
              <div class="job">实际结束</div>
            </div>
          </div>
          <div class="item">
            <a-avatar :size="32"
                      :src="`${$appsettings.imgPrefix}tophase.png`"/>
            <div class="user">
              <div class="name">
                <!--                {{issueData.deadline}}-->
                <edp-select
                  size="mini"
                  v-model="issueData.phase_oid"
                  :db="dbs.prjIssue"
                  field-name="phase_oid"
                  :oid="issueData.oid"
                  :prj-oid="issueData.prjoid"
                  @change="$emit('reload')"
                  :options="stageList"
                >
                </edp-select>
              </div>
              <div class="job">所属阶段</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-left">
        <div class="content-left-head">
          <a-tabs>
            <a-tab-pane key="1">
                <span slot="tab">
                  交付物
                   <span class="num">{{ issueSta.delivery_finish || 0 }}/{{ issueSta.delivery_totalcount }}</span>
                </span>
              <div class="tabs-content">
                <div class="head">
                  <div>交付物</div>
                  <div class="num">已提交{{ issueSta.delivery_finish || 0 }}项，共{{ issueSta.delivery_totalcount }}项
                  </div>
                </div>
                <div class="table">
                  <a-table :data-source="deliveryList" rowKey="oid" :pagination="false" :customRow="customRow">
                    <a-table-column title="ID" data-index="seqid" width="100px"/>
                    <a-table-column title="目标名称" data-index="delivery_name"/>
                    <a-table-column title="类型" data-index="delivery_type_name" width="180px"/>
                    <a-table-column title="交付数量" data-index="delivery_count" width="100px"/>
                    <a-table-column title="交付者" data-index="delivery_user" width="140px">
                      <template slot-scope="scope, row">
                        <div class="user" v-if="scope">
                          <a-avatar :size="21"
                                    :src="$toUrl(row.delivery_user_pic)"/>
                          <div>{{ row.delivery_user_title ? scope + '-' + row.delivery_user_title : scope }}</div>
                        </div>
                      </template>
                    </a-table-column>
                    <a-table-column title="状态" data-index="delivery_status_name" width="100px"></a-table-column>
                    <a-table-column title="交付文件" width="220px">
                      <template #default="_, row">
                        <div v-if="row.att?.file_path && row.att?.file_path !== 'APPNULL'" @click="$preview(row.att, true)" class="file-link">
                          {{row.att.file_name + row.att.file_type}}
                        </div>
                      </template>
                    </a-table-column>
                    <a-table-column title="操作" width="200px" data-index="operation">
                      <template slot-scope="scope, row, index">
                        <div class="operation">
                          <div v-if="row.delivery_cat_type === 'P' && row.delivery_status_name !== '已完成'" @click.stop="finish(row)">完成</div>
                          <div v-if="row.delivery_cat_type === 'D' && row.att !== null" @click.stop="downLoadFile($toUrl(row.att?.file_path))">下载文件</div>
                          <div  v-if="row.delivery_cat_type === 'D'" @click.stop="openFileModal(row)">上传文件</div>
                          <div @click.stop="deleteDelivery(row.oid, index)">删除</div>
                        </div>
                      </template>
                    </a-table-column>
                  </a-table>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="详情">
              <div class="tabs-content">
                <div class="item">
                  <div class="title">描述</div>
                  <a-textarea v-model="issueData.issue_desc" :rows="4"/>
                </div>
                <div class="item">
                  <div class="title">属性</div>
                  <div class="d-flex a-i-center j-c-space-b">
                    <div class="info">
                      <span>类型</span>
                      <span>{{issueData.issue_type_name}}</span>
                    </div>
                    <div class="info d-flex a-i-center">
                      <span>创建者</span>
                      <span class="d-flex a-i-center">
                          <img class="avatar" :src="issueData.creator_pic" alt="">
                          <span style="margin: 0px 5px 0 8px; height: 21px;">{{ issueData.creator_name }}</span>
                          <span style="color: #737373; font-size: 12px; height: 21px;">（{{ issueData.creator_mobile }}）</span>
                        </span>
                    </div>
                    <div class="info">
                      <span>创建时间</span>
                      <span>{{ issueData.create_time }}</span>
                    </div>
                    <div class="info">
                      <span>更新时间</span>
                      <span>{{ issueData.update_time }}</span>
                    </div>
                  </div>
                </div>
                <div class="item" style="width: 542px">
                  <attachment-upload :source-oid="oid" :prj-oid="prj_oid" source-type="3">
                    <template slot="title">
                      文件
                    </template>
                  </attachment-upload>
                </div>
              </div>
            </a-tab-pane>
            <div class="tab-btn" slot="tabBarExtraContent" @click="openDeliverModal">
              <a-icon type="setting"/>
              设置目标交付物
            </div>
          </a-tabs>
        </div>
      </div>
      <input style="position: fixed;z-index: -999;left: -100%;" type="file" ref="file" @change="uploadFile">
      <div class="content-right edp-right-detail" style="--detailWidth: 430px" v-if="Object.keys(rightData).length">
        <project-right-detail-modal ref="RightDetail" :logOid="issueData.oid" :right-data="rightData"></project-right-detail-modal>
      </div>
    </div>
    <set-delivery-modal ref="deliverModal" :prj-oid="prj_oid" :issue-oid="oid" :delivery-list="deliveryList" @reload="getDeliveryList"></set-delivery-modal>
    <upload-modal ref="uploadModal" :source-oid="source_oid" :prj-oid="prj_oid" source-type="1" url="prj/delivery/file/upload" @reload="()=>{
      issueData.oid = ''
      getDeliveryList()
      getIssueDetails(oid)
    }"></upload-modal>
  </div>
</template>

<script>
import attachmentUpload from "@/components/attachmentUpload";
import uploadModal from "@/components/uploadModal.vue";
import projectRightDetailModal from "@/components/projectRightDetail";
import {fetch} from "@/utils/request";
import setDeliveryModal from "@/components/setDeliveryModal.vue";
import catMixin from "@/utils/mixins/catMixin.js";
import EdpDatePicker from "@/components/e-form/edp-date-picker.vue";
import {FieldUpdateDb} from "@/utils/FieldUpdate.js";
import EdpInput from "@/components/e-form/edp-input.vue";
import EdpSelect from "@/components/e-form/edp-select.vue";

export default {
  name: "projectPlanMilestoneDetails",
  mixins: [catMixin],
  data() {
    return {
      dbs: {
        prjIssue: new FieldUpdateDb("prj", "prj_issue")
      },
      cat: {
        issue_bstatus: []
      },
      accountList: [],
      issueData: {
        oid: '',
        owners: [{pic: ''}]
      },
      deliveryList: [],
      issueSta: {},
      prj_oid: '',
      oid: '',
      rightData: {}, // 右侧详情数据
      source_oid: '',
      stageList: []
    }
  },
  components: {
    EdpSelect,
    EdpInput, EdpDatePicker, projectRightDetailModal, attachmentUpload, setDeliveryModal, uploadModal },
  created() {
    let {prj_oid, oid} = this.$route.query
    this.oid = oid
    this.prj_oid = prj_oid
  },
  mounted() {
    this.getIssueDetails(this.oid)
    this.getDeliveryList()
    this.getIssueSta(this.oid, this.prj_oid)
    this.getAccount()
  },
  methods: {
    getStageList() {
      fetch("post", "/prj/phase/retrieve", { prjoid: this.issueData.prjoid, page_size: 999 }).then(res => {
        if (res.status === 0) {
          this.stageList = res.data.datas.map(item => {
            return {
              label: item.phase_name,
              value: item.oid,
              key: item.oid
            }
          })
        }
      })
    },
    finish(row) {
      fetch("post", "prj/delivery/finish", {
        oid: row.oid
      }).then(res => {
        if (res.status === 0) {
          this.getDeliveryList()
        }
      })
    },
    getAccount() {
      fetch("post", "/prj/userlist/list", {prjoid: this.$route.query.prj_oid}).then(res => {
        if (res.status === 0) {
          this.accountList = res.data
        }
      })
    },
    openFileModal(row) {
      console.log(row);
      this.source_oid = row.oid
      this.$refs.uploadModal.open(row.att)
      // this.$refs.file.click()
    },
    uploadFile(e) {
      let formData = new FormData()
      formData.set("file", e.target.files[0])
      formData.set("source_oid", this.source_oid)
      formData.set("sec_level", 0)
      fetch("post", "/prj/delivery/file/upload", formData).then(res => {
        if (res.status === 0) {
          e.target.value = ''
          this.$message.success("上传成功")
          this.getDeliveryList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    back(){
      this.$router.back()
    },
    getDeliveryList() {
      fetch('post', '/prj/delivery/list', {
        issue_oid: this.oid
      }).then((res) => {
        if (res.status === 0) {
          console.log(res.data);
          this.deliveryList = res.data
          if (this.deliveryList.length > 0) this.rightData = this.deliveryList[0]
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getIssueDetails(oid) {
      fetch('post', '/prj/issue/details', {
        oid
      }).then((res) => {
        if (res.status === 0) {
          console.log(res.data);
          this.issueData = res.data
          this.getStageList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getIssueSta(issue_oid, prjoid) {
      fetch('post', '/prj/delivery/sta', {
        issue_oid,
        prjoid
      }).then((res) => {
        if (res.status === 0) {
          console.log(res.data);
          this.issueSta = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    customRow(row) {
      return {
        on: {
          click: () => {
            console.log(row);
            this.rightData = row
          }
        }
      }
    },
    openDeliverModal() {
      this.$refs.deliverModal.open()
    },
    deleteDelivery(oid) {
      fetch('post', '/prj/delivery/delete', {
        oid
      }).then((res) => {
        if (res.status === 0) {
          this.$message.success('删除成功！')
          window.location.reload()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    downLoadFile(url) {
      window.open(url, '_self')
    }
  }
}
</script>

<style scoped lang="less">
.file-link {
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #4397E7;
}
.app-main-content {
  padding: 0 10px;
  //height: calc(100% - 50px);
  box-shadow: 0 2px 10px 0 rgba(182,182,182,0.50);

  .header {
    width: 100%;
    background: #FFFFFF;

    .header-top {
      box-sizing: border-box;
      height: 47px;
      border-bottom: 1px solid #CACACA;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;

      .header-title {
        display: flex;
        align-items: center;

        .name {
          text-align: center;
          font-weight: 500;
          font-size: 12px;
          color: var(--primary-color);
          padding: 4px 6px;
          background: #EAF3FC;
          border-radius: 3px;
          margin-right: 5px;
        }

        .id {
          font-weight: 500;
          font-size: 12px;
          color: #909090;
          text-align: center;
          padding: 4px 6px;
          background: #E8E8E8;
          border-radius: 3px;
        }
      }

      .header-btns{
        button{
          color: #6E6E6E;
          border-color: #979797;
          display: flex;
          align-items: center;

          >i{
            height: 20px;
            margin-right: 11px;
            font-size: 16px;
            vertical-align: middle;
          }

          >img{
            width: 13px;
            height: 15px;
            vertical-align: middle;
            margin-right: 11px;
          }
        }
      }
    }

    .header-bottom {
      height: 110px;
      padding: 15px 20px 0;

      .header-title {
        font-weight: 500;
        font-size: 18px;
        color: #494949;
        letter-spacing: 1px;
      }

      .header-info {
        margin-top: 10px;
        display: flex;
        align-items: center;

        .item {
          display: flex;
          align-items: center;
          margin-right: 48px;

          .user {
            margin-left: 7px;
            font-weight: 500;
            font-size: 12px;
            color: #909090;
            letter-spacing: 1px;

            .name {
              font-weight: 500;
              font-size: 16px;
              color: #494949;
            }
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 157px);
    padding-top: 10px;
    display: flex;
    align-items: center;
    overflow: hidden;

    .content-left {
      flex: 1;
      height: 100%;
      background-color: #FFF;
      overflow-y: auto;

      .content-left-head {
        height: 100%;
        //padding: 0 14px;
        //overflow-y: auto;

        ///deep/ .ant-tabs{
        //  height: 100%;
        //}

        /deep/ .ant-tabs-nav-wrap {
          padding-left: 14px;
        }

        /deep/ .ant-tabs-nav .ant-tabs-tab {
          margin: 0;

          &.ant-tabs-tab-active{
            .num{
              background-color: var(--primary-color);
            }
          }

          .num{
            display: inline-block;
            height: 14px;
            line-height: 14px;
            padding: 0 3px;
            text-align: center;
            font-size: 11px;
            color: #FFFFFF;
            background-color: #303030;
            border-radius: 8px;
            margin-left: 6px;
          }
        }

        .tab-btn {
          cursor: pointer;
          margin-right: 40px;
          font-weight: 500;
          font-size: 16px;
          color: #494949;
        }

        .tabs-content {

          .head {
            font-weight: 500;
            font-size: 16px;
            color: #494949;
            letter-spacing: 1px;
            padding: 7px 0 15px 15px;

            .num {
              font-weight: 400;
              font-size: 12px;
              color: #494949;
            }
          }

          .table {

            .user {
              font-size: 14px;
              color: #303030;
              display: flex;
              align-items: center;

              > .ant-avatar {
                margin-right: 3px;
              }
            }

            .operation {
              display: flex;
              align-items: center;
              font-weight: 400;
              font-size: 14px;
              color: #4397E7;

              & > div {
                cursor: pointer;
                margin-right: 10px;
              }

              > div:nth-last-child(1) {
                margin-right: 0px;
              }
            }
          }

          .item {
            padding: 0 22px 18px;

            .title {
              font-size: 16px;
              font-weight: 500;
              color: #303030;
              margin-bottom: 10px;
            }

            .info {
              font-size: 14px;
              color: #303030;

              span{
                .avatar{
                  width: 16px;
                  height: 16px;
                  display: block;
                  object-fit: cover;
                  border-radius: 50%;
                }
              }

              > span:nth-of-type(1) {
                margin-right: 35px;
              }
            }
          }
        }
      }
    }

    .content-right {
      height: 100%;
      background-color: #FFF;
      margin-left: 13px;
      flex-shrink: 0;
    }
  }
}
</style>
